import React, { useState, useEffect } from "react";
import { db, auth } from "../config/firebase-config";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  getDoc,
  where,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
import moment from "moment";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import MsgBox from "./MsgBox";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import axios from "axios";

const ModalFahrzeug = (props) => {
  const today = new Date();

  const [funkkennungen, setFunkkennungen] = useState([]);
  const [standorte, setStandorte] = useState([]);
  const [funkkennung, setFunkkennung] = useState("");
  const [standort, setStandort] = useState("");
  const [bemerkung, setBemerkung] = useState("");
  const [id, setId] = useState("");
  const [funkkennungAkt, setFunkkennungAkt] = useState("");
  const [needFunkkennung, setNeedFunkkennung] = useState();
  const [needDienstort, setNeedDienstort] = useState();
  const [standortTyp, setStandortTyp] = useState("");
  const [dataError, setDataError] = useState(true);
  const [funkkennungError, setFunkkennungError] = useState(true);
  const [standortError, setStandortError] = useState(true);
  const [bemerkungError, setBemerkungError] = useState(true);
  const [bemerkungChanged, setBemerkungChanged] = useState(false);
  const [kilometer, setKilometer] = useState("");

  const [desDate, setDesDate] = useState();

  const funkkennungenRef = collection(db, "funkkennungen");
  const standorteRef = collection(db, "standorte");
  const protokollRef = collection(db, "protokoll");

  useEffect(() => {
    const queryFunkkennungen = query(funkkennungenRef, orderBy("bezeichnung"));

    const unsubscribe = onSnapshot(queryFunkkennungen, (snapshot) => {
      let funkkennungen = [];
      snapshot.forEach((doc) => {
        funkkennungen.push({ ...doc.data(), id: doc.id });
      });
      setFunkkennungen(funkkennungen);
    });

    const queryStandorte = query(standorteRef, orderBy("bezeichnung"));

    const unsubscribeStandorte = onSnapshot(queryStandorte, (snapshot) => {
      let standorte = [];
      snapshot.forEach((doc) => {
        standorte.push({ ...doc.data(), id: doc.id });
      });
      setStandorte(standorte);
    });

    return () => {
      unsubscribe();
      unsubscribeStandorte();
    };
  }, []);

  useEffect(() => {
    // console.log("props.funkkennung", props.funkkennung);
    setFunkkennung(props.funkkennung);
  }, [props.funkkennung]);

  useEffect(() => {
    if (props.fahrzeug.kilometerstand) {
      setKilometer(props.fahrzeug.kilometerstand);
    } else {
      setKilometer("");
    }
  }, [props.fahrzeug.kilometerstand]);

  useEffect(() => {
    if (props.fahrzeug.desDate) {
      setDesDate(new Date(props.fahrzeug.desDate.seconds * 1000).toString());
    } else {
      setDesDate(new Date().toString());
    }
  }, [props.fahrzeug.desDate]);

  useEffect(() => {
    // Plausibilitätsprüfung Funkrufkennung

    if (props.showModal) {
      let statusTmp = props.fahrzeug.status;
      if (props.target.length > 0) statusTmp = props.target;

      if (
        statusTmp === "einsatzbereit" ||
        statusTmp === "taktischeReserve" ||
        statusTmp === "sonderbedarf" ||
        statusTmp === "fuehrung"
      )
        setNeedFunkkennung(true);
      else {
        setNeedFunkkennung(false);
      }

      if (needFunkkennung && funkkennung === "") {
        // setNeedFunkkennung(true);

        setFunkkennungError(true);
      } else {
        setFunkkennungError(false);
      }
    }
  }, [
    props.showModal,
    props.fahrzeug.status,
    props.target,
    needFunkkennung,
    funkkennung,
  ]);

  useEffect(() => {
    // Plausibilitätsprüfung Dienstort

    if (props.showModal) {
      let statusTmp = props.fahrzeug.status;
      if (props.target.length > 0) statusTmp = props.target;

      if (
        statusTmp === "einsatzbereit" ||
        statusTmp === "taktischeReserve" ||
        statusTmp === "sonderbedarf" ||
        statusTmp === "fuehrung"
      )
        setNeedDienstort(true);
      else {
        setNeedDienstort(false);
      }

      standorte.forEach((standort) => {
        if (standort.bezeichnung === props.standort)
          setStandortTyp(standort.typ);
      });

      if (needDienstort && standortTyp !== "Dienstort") {
        // setNeedFunkkennung(true);

        setStandortError(true);
      } else {
        setStandortError(false);
      }
    }
  }, [
    props.fahrzeug.status,
    props.target,
    needDienstort,
    props.standort,
    standorte,
    standortTyp,
    props.showModal,
  ]);

  useEffect(() => {
    if (!bemerkungChanged || props.bemerkung === "") {
      setBemerkungError(true);
    } else {
      setBemerkungError(false);
    }
  }, [bemerkungChanged, props.bemerkung]);

  const changeKm = (e) => {
    let valueTmp = e.target.value;

    const numericString = valueTmp.replace(/\D/g, "");

    // Wenn der String leer ist, gib eine leere Zeichenkette zurück
    if (numericString === "") return "";

    // Wandelt die Zahl in einen Integer um
    const number = parseInt(numericString, 10);

    setKilometer(number.toLocaleString("de-DE"));
  };

  const updateFahrzeug = () => {
    const docRef = doc(db, "fahrzeuge", props.id);

    let date = new Date();
    const localDate = date.toLocaleString();

    let newStatus = false;

    let statusTmp = props.fahrzeug.status;
    if (props.target.length > 0) {
      statusTmp = props.target;
      newStatus = true;
    }

    let funkkennungAltTmp = props.fahrzeug.funkkennung;
    if (funkkennung === funkkennungAltTmp) {
      // console.log("funkkenung unverändert (fahrzeug)", props.fahrzeug);
      if (props.fahrzeug.funkkennungAlt) {
        funkkennungAltTmp = props.fahrzeug.funkkennungAlt;
      } else {
        funkkennungAltTmp = "";
      }
    }

    let funkkennungTmp = funkkennung;
    if (!needFunkkennung) {
      funkkennungTmp = "";
      funkkennungAltTmp = props.fahrzeug.funkkennung;
    }

    let data = {};
    if (newStatus) {
      data = {
        bemerkung: props.bemerkung,
        funkkennung: funkkennungTmp,
        funkkennungAlt: funkkennungAltTmp,
        standort: props.standort,
        letztesUpdate: localDate,
        status: statusTmp,
        desDate: new Date(desDate),
        kilometerstand: kilometer,
      };
    } else {
      data = {
        bemerkung: props.bemerkung,
        funkkennung: funkkennungTmp,
        funkkennungAlt: funkkennungAltTmp,
        standort: props.standort,
        status: statusTmp,
        desDate: new Date(desDate),
        kilometerstand: kilometer,
      };
    }

    setDoc(docRef, data, { merge: true })
      .then((docRef) => {
        // console.log("Document Field has been updated successfully", data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const writeToProtokoll = () => {
    let date = new Date();

    const localDate = date.toLocaleString().replace(" ", "");
    const datumSplit = localDate.split(",");
    const datum = datumSplit[0].split(".");

    const datumParse =
      datum[1] + "/" + datum[0] + "/" + datum[2] + " " + datumSplit[1];

    let statusTmp = props.fahrzeug.status;
    if (props.target.length > 0) statusTmp = props.target;

    let funkkennungAltTmp = props.fahrzeug.funkkennung;
    if (funkkennung === funkkennungAltTmp) {
      // console.log("funkkenung unverändert", props.fahrzeug);
      if (props.fahrzeug.funkkennungAlt) {
        funkkennungAltTmp = props.fahrzeug.funkkennungAlt;
      } else {
        funkkennungAltTmp = "";
      }
    }

    let funkkennungTmp = funkkennung;
    if (!needFunkkennung) {
      funkkennungTmp = "";
      funkkennungAltTmp = props.fahrzeug.funkkennung;
    }

    // console.log("updateProtokoll", props.fahrzeug);
    const data = {
      datum: "->" + localDate,
      fahrzeug: props.fahrzeug.kennzeichen,
      kennung: funkkennungTmp,
      kennungAlt: funkkennungAltTmp,
      standort: props.standort,
      status: statusTmp,
      bemerkung: props.bemerkung,
      timestamp: Date.parse(datumParse),
      desDate: new Date(desDate),
      kilometerstand: kilometer,
    };

    addDoc(protokollRef, data)
      .then((docRef) => {
        // console.log("Document Field has been updated successfully");
        sendNotifyEmail(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendNotifyEmail = (data) => {
    // console.log("sendNotifyEmail called");

    // const emailData = {
    //   emailTo: "toni.fredrich@drk-bremen.de",
    //   kennzeichen: data.fahrzeug,
    //   kennung: data.kennung,
    //   standort: data.standort,
    //   fzStatus: data.status,
    //   bemerkung: data.bemerkung,
    //   desDate: data.desDate,
    //   kilometerstand: data.kilometerstand,
    //   timestamp: data.timestamp,
    // };

    const emailData = {
      emailTo: "helfrich@moinmoin-media.de",
      kennzeichen: data.fahrzeug,
      kennung: data.kennung,
      standort: data.standort,
      fzStatus: data.status,
      bemerkung: data.bemerkung,
      desDate: data.desDate,
      kilometerstand: data.kilometerstand,
      timestamp: data.timestamp,
    };

    axios
      .post("https://fleethq-email.osc-fr1.scalingo.io/api/email", emailData)
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        // console.log(error);
      });

    // axios
    //   .post("http://localhost:3000/api/email", emailData)
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // console.log("sendNotifyEmail finished");
  };

  const handleSave = () => {
    if (needFunkkennung)
      props.updateFunkkennung(props.funkkennung, funkkennung);
    else {
      props.updateFunkkennung(props.funkkennung, "");
    }

    updateFahrzeug();
    writeToProtokoll();
    handleClose();
  };

  const handleClose = () => {
    props.closeModal();
    setId("");
    setFunkkennung(props.funkkennung);
    setBemerkungError(true);
    setFunkkennungError(true);
    setStandortError(true);
    setBemerkungChanged(false);
  };

  const changeFunkkennung = (funkkennung) => {
    setFunkkennung(funkkennung);
  };

  // if (props.showModal) {
  //   if (id !== props.id) {
  //     setId(props.id);
  //     if (funkkennung !== props.fahrzeug.funkkennung) setFunkkennung(props.fahrzeug.funkkennung);
  //     if (standort !== props.fahrzeug.standort) setStandort(props.fahrzeug.standort);
  //   }
  // }
  // console.log("props.fahrzeug", props.fahrzeug);
  // console.log("props.fahrzeuge", props.fahrzeuge);

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Fahrzeugdaten bearbeiten</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Funkkennung</Form.Label>
              <Form.Select
                value={funkkennung}
                name="modalfunkkennung"
                onChange={(e) => changeFunkkennung(e.target.value)}
              >
                <option value="">Keine Funkkennung</option>
                {props.funkkennungen.map((item) => {
                  if (!item.inUse) {
                    return (
                      <option key={item.id} value={item.bezeichnung}>
                        {item.bezeichnung} (frei)
                      </option>
                    );
                  } else if (item.bezeichnung === props.funkkennung) {
                    return (
                      <option key={item.id} value={item.bezeichnung}>
                        {item.bezeichnung} (aktuell)
                      </option>
                    );
                  }
                })}
              </Form.Select>
            </Form.Group>
            {needFunkkennung && funkkennung === "" ? (
              <MsgBox
                msg="Es muss eine Funkkennung gewählt werden!"
                type="error"
              />
            ) : undefined}
            {!needFunkkennung && funkkennung !== "" ? (
              <MsgBox
                msg="Funkkennung wird beim Speichern entfernt!"
                type="info"
              />
            ) : undefined}
            <Form.Group className="mb-3">
              <Form.Label>Standort</Form.Label>
              <Form.Select
                value={props.standort}
                onChange={(e) => props.setStandort(e.target.value)}
              >
                <option value="">Kein Standort ausgewählt</option>
                {standorte.map((item) => {
                  return (
                    <option key={item.id} value={item.bezeichnung}>
                      {item.bezeichnung} | {item.typ}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            {needDienstort && standortTyp !== "Dienstort" ? (
              <MsgBox
                msg="Es muss ein Dienstort gewählt werden!"
                type="error"
              />
            ) : undefined}

            <Form.Group className="mb-3">
              <Form.Label>Letzte Grunddesinfektion</Form.Label>
              <div className="datepicker">
                <DatePicker onChange={setDesDate} value={desDate} />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputKilomter">Kilometerstand</Form.Label>
              <Form.Control
                type="text"
                id="inputKilomter"
                value={kilometer}
                onChange={(e) => {
                  changeKm(e);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Bemerkung</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={props.bemerkung}
                onChange={(e) => {
                  props.setBemerkung(e.target.value);
                  setBemerkungChanged(true);
                }}
              />
            </Form.Group>
            {bemerkungError && props.bemerkung !== "" ? (
              <MsgBox msg="Die Bemerkung muss angepasst werden" type="error" />
            ) : undefined}
            {props.bemerkung === "" ? (
              <MsgBox msg="Die Bemerkung darf nicht leer sein" type="error" />
            ) : undefined}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Schließen
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={funkkennungError || standortError || bemerkungError}
          >
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalFahrzeug;
